import { EnvironmentConfig } from '@trtl/env';

export const environment: EnvironmentConfig = {
  production: true,
  api: 'https://api.production.trtl.health',
  ws: 'wss://api.production.trtl.health',
  sentry: {
    dsn: 'https://ab6d70aa68a92c7cbaeab217699f1900@o4508120270372864.ingest.de.sentry.io/4508120417632336',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /https:\/\/[^.]+\.production\.trtl\.health/],
  },
  environment: 'production',
  trtl: 'https://trtl.health',
};
